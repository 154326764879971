// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
@use "./variables" as *;
@use "./typography" as *;
@use "./material" as *;

// ngx-drag-to-select

@use "ngx-drag-to-select" as *
  with(
    $box-shadow: false,
    $selected-item-border: false,
    $range-start-border: false
  );

html,
body {
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: $body-background-color;
  color: #404040;
}

.verify-dialog {
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.container {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: $spacer * 2;
  border: 1px solid rgba(229, 231, 237, 0.75);
  box-shadow: 0px 1px 18px -10px rgba(0, 0, 0, 0.25);
}

.status-tag,
.status-tag-dark {
  display: inline-flex;
  padding: $spacer * 0.5 $spacer * 2;
  align-items: center;
  border-radius: 16px;
  font-size: 12px;
  background-color: white;
  color: #666666;
  text-transform: uppercase;
  height: 32px;
  box-sizing: border-box;

  &-icon {
    margin-right: $spacer;
    margin-left: -$spacer;
    color: mat.get-theme-color($verify-theme, primary, 400);
  }

  &.status-tag-dark {
    background-color: $border-color;
    .status-tag-icon {
      color: #666666;
      &-revoked {
        color: $status-color-revoked;
      }
      &-pending,
      &-extend_request {
        color: $status-color-pending;
      }
      &-expired {
        color: $status-color-expired;
      }
    }
  }
}

// .status-tag-dark {
//   background-color: mat.get-theme-color($verify-theme, primary, 100);
//   .status-tag-icon {
//     color: #666666;
//   }
// }

.dialog-header {
  margin-bottom: $spacer * 3;
}

.spinner {
  margin: $spacer * 2 auto;
}

.flex-spacer {
  flex: 1;
}

.list-container {
  background-color: white;
  //border-radius: 8px;
  // border: 1px solid rgba(229, 231, 237, 0.75);
  // box-shadow: 0px 1px 18px -10px rgba(0, 0, 0, 0.25);
  margin-bottom: $spacer;
  overflow: hidden;

  &-toolbar {
    padding: 0 $spacer;
    display: flex;
    align-items: center;
    column-gap: $spacer;
    min-height: 50px;
    border-bottom: 1px solid $border-color;
  }

  &-search {
    margin-right: $spacer;
  }

  &-tablewrapper {
    overflow-x: auto;
  }

  &-paginator {
    margin: 0 $spacer;
  }

  &-spinner {
    margin-left: $spacer;
  }

  .list-container-table {
    // border-top: 1px solid mat.get-theme-color($verify-theme, primary, 100);
    // border-bottom: 1px solid mat.get-theme-color($verify-theme, primary, 100);

    &-header {
      th {
        border-bottom-style: none;
      }
    }

    &-row:first-child {
      &:hover {
        background-color: mat.get-theme-color($verify-theme, primary, 50);
        cursor: pointer;
      }

      td {
        border-top: 1px solid $border-color;
      }
      td:first-child {
        border-top-left-radius: $spacer;
      }
      td:last-child {
        border-top-right-radius: $spacer;
      }
    }

    &-row {
      td {
        border-bottom: 1px solid $border-color;
      }

      td:first-child {
        border-left: 1px solid $border-color;
      }

      td:last-child {
        border-right: 1px solid $border-color;
      }
    }

    &-row:last-child {
      td {
        border-bottom: 1px solid $border-color;
      }

      td:first-child {
        border-bottom-left-radius: $spacer;
      }

      td:last-child {
        border-bottom-right-radius: $spacer;
      }
    }

    &:has(.list-container-table-detail-row) {
      .list-container-table-row td:not(.mat-column-expandedDetail) {
        border-bottom-style: none;
      }
    }

    &-detail {
      &-row {
        height: 0;

        td {
          border-bottom: 1px solid $border-color;
        }

        td:first-child {
          border-left: 1px solid $border-color;
        }

        td:last-child {
          border-right: 1px solid $border-color;
        }
      }
      &-row:last-child {
        td {
          border-bottom: 1px solid $border-color;
        }

        td:first-child {
          border-bottom-left-radius: $spacer;
        }

        td:last-child {
          border-bottom-right-radius: $spacer;
        }
      }
      &-content {
        overflow: hidden;
      }
    }
  }

  // .list-container-table {
  //   border-top: 1px solid mat.get-theme-color($verify-theme, primary, 100);
  //   border-bottom: 1px solid mat.get-theme-color($verify-theme, primary, 100);

  //   &-header {
  //     background-color: mat.get-theme-color($verify-theme, primary, 50);
  //   }

  //   &-row:hover {
  //     background-color: mat.get-theme-color($verify-theme, primary, 50);
  //     cursor: pointer;
  //   }

  //   &-row td:not(.mat-column-expandedDetail) {
  //     border-bottom-style: none;
  //   }

  //   &-detail {
  //     &-row {
  //       height: 0;
  //     }
  //     &-content {
  //       overflow: hidden;
  //     }
  //   }
  // }
}

/*
  Material overrides
*/
.mat-mdc-icon-button {
  &.mat-mdc-button-base {
    width: 40px;
    height: 40px;
    padding: 7px;
  }

  &.stroked-icon-button,
  &.stroked-icon-button-square {
    border: 1px solid var(--mdc-outlined-button-outline-color);
  }
}

.mat-mdc-outlined-button {
  &.stroked-icon-button-square {
    min-width: 0;
    padding: 0 10px;

    .mat-icon {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.mat-mdc-menu-panel {
  max-width: 380px !important;
}

.mat-drawer-side.mat-drawer-end {
  box-shadow: 0px 1px 18px -10px rgba(0, 0, 0, 0.25);
}
