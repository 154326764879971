@use "@angular/material" as mat;
@use "./variables" as *;
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($verify-theme);

/* You can add global styles to this file, and also import other style files */
:root {
  --mat-toolbar-container-background-color: white;
  --mat-toolbar-standard-height: 68px;
  --mat-toolbar-mobile-height: 68px;
  --mat-dialog-actions-padding: 16px;
  --mat-snack-bar-button-color: white;
  --mdc-icon-button-state-layer-size: 40px;
  --mdc-protected-button-container-shape: 9999px;
  --mat-sidenav-content-background-color: none;
  --mdc-outlined-button-label-text-transform: uppercase;
  --mdc-outlined-button-outline-color: #e8ebf0;
  --mdc-outlined-text-field-outline-color: #e8ebf0;
  --mat-standard-button-toggle-height: 34px;
  --mat-standard-button-toggle-divider-color: #e8ebf0;
  --mat-sidenav-container-divider-color: rgba(229, 231, 237, 0.75);

  --mdc-outlined-button-container-shape: 9999px;
  --mdc-outlined-button-container-height: 40px;

  --mdc-filled-button-container-shape: 9999px;
  --mdc-filled-button-container-height: 40px;

  --mat-standard-button-toggle-shape: 9999px;
  --mat-standard-button-toggle-height: 40px;
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-outline-color: #e8ebf0;
}

// .mdc-button.mat-mdc-outlined-button {
//   background: white;
// }
