h1,
.text-headline-1 {
  font-size: 96px;
  font-style: normal;
  font-weight: 300;
  line-height: 112px; /* 116.667% */
  letter-spacing: -1.5px;
  margin: 0;
}

h2,
.text-headline-2 {
  font-size: 60px;
  font-style: normal;
  font-weight: 300;
  line-height: 72px; /* 120% */
  letter-spacing: -0.5px;
  margin: 0;
}

h3,
.text-headline-3 {
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px; /* 116.667% */
  margin: 0;
}

h4,
.text-headline-4 {
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 105.882% */
  margin: 0;
}

h5,
.text-headline-5 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  letter-spacing: 0.18px;
  margin: 0;
}

h6,
.text-headline-6 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  letter-spacing: 0.15px;
  margin: 0;
}

p,
.text-body-1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.text-body-2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
  margin: 0;
}

.text-subtitle-1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
}

.text-subtitle-2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.1px;
}

.text-caption {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
}
