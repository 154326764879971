@use "@angular/material" as mat;
@use "sass:map";

// $verify-primary-palette: (
//   50: #f4f5fb,
//   100: #e8ebf0,
//   200: #c5cedc,
//   300: #a0aec4,
//   400: #7c8eac,
//   500: #60769b,
//   600: #445e8d,
//   700: #354d78,
//   800: #2e436b,
//   900: #1f3259,
//   contrast: (
//     50: rgba(black, 0.87),
//     100: rgba(black, 0.87),
//     200: rgba(black, 0.87),
//     300: rgba(black, 0.87),
//     400: rgba(black, 0.87),
//     500: white,
//     600: white,
//     700: white,
//     800: white,
//     900: white,
//   ),
// );
$verify-primary-palette: (
  50: var(--theme-dynamic-palette-50),
  100: var(--theme-dynamic-palette-100),
  200: var(--theme-dynamic-palette-200),
  300: var(--theme-dynamic-palette-300),
  400: var(--theme-dynamic-palette-400),
  500: var(--theme-dynamic-palette-500),
  600: var(--theme-dynamic-palette-600),
  700: var(--theme-dynamic-palette-700),
  800: var(--theme-dynamic-palette-800),
  900: var(--theme-dynamic-palette-900),
  contrast: (
    50: var(--theme-dynamic-palette-contrast-50),
    100: var(--theme-dynamic-palette-contrast-100),
    200: var(--theme-dynamic-palette-contrast-200),
    300: var(--theme-dynamic-palette-contrast-300),
    400: var(--theme-dynamic-palette-contrast-400),
    500: var(--theme-dynamic-palette-contrast-500),
    600: var(--theme-dynamic-palette-contrast-600),
    700: var(--theme-dynamic-palette-contrast-700),
    800: var(--theme-dynamic-palette-contrast-800),
    900: var(--theme-dynamic-palette-contrast-900),
  ),
);

$verify-secondary-palette: (
  50: #e3eaff,
  100: #bdcfea,
  200: #9cadcc,
  300: #798db0,
  400: #60769b,
  500: #476187,
  600: #395377,
  700: #2a4161,
  800: #1b304b,
  900: #071d34,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$verify-primary: mat.m2-define-palette($verify-primary-palette, 500);
$verify-accent: mat.m2-define-palette($verify-secondary-palette, 900);

// The warn palette is optional (defaults to red).
$verify-warn: mat.m2-define-palette(mat.$m2-orange-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$verify-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $verify-primary,
      accent: $verify-accent,
      warn: $verify-warn,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

$background: map.get($verify-theme, background);
$background: map.merge(
  $background,
  (
    background: red,
  )
);
$verify-theme: map.merge($verify-theme, $background);

$primary-default: mat.get-theme-color($verify-theme, primary, default);
$secondary-default: mat.get-theme-color($verify-theme, accent, default);

$body-background-color: #ffffff; //#f8f9ff;
$tile-background-color: #f4f5fb;
$border-color: #e8ebf0;
$status-color-signed: #e8ebf0;
$status-color-pending: #ffb016;
$status-color-expired: #ffb016;
$status-color-revoked: #e31424;

$spacer: 8px;
$toolbar-height: var(--mat-toolbar-standard-height);
$layout-width: 1640px; //1045px;
$menu-width: 64px;
$menu-width-expanded: 256px;
